import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
// import * as moment from 'moment';
import { formatDate } from 'date-fns';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-datetime-picker',
  templateUrl: './n7-datetime-picker.component.html',
  styleUrls: ['./n7-datetime-picker.component.scss'],
})
export class N7DatetimePickerComponent implements OnChanges {
  public index: number;
  public selfRef: N7DatetimePickerComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemHint: string;
  public itemHintType: string;
  public itemHasError: boolean;
  public itemDisabled: boolean = false;
  public itemEditable: boolean = false;
  public itemError: string;
  public itemTitle: string = 'Select date';
  public itemValue: string;
  public itemMinValue: string;
  public itemMaxValue: string;
  public itemData: string = null;
  public itemDisplay: string;
  public itemDisplayFormat: string = 'yyyy-MM-dd HH:mm:ss';
  public itemValueFormat: string = 'yyyy-MM-dd HH:mm:ss';
  showCalendar = false;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hintType(val: string) {
    this.itemHintType = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set disabled(val: boolean) {
    this.itemDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set editable(val: boolean) {
    this.itemEditable = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set value(val: string) {
    this.itemData = (val !== undefined && val !== null) ? val : null;
    if (this.itemData && ('' !== this.itemData)) {
      // this.itemValue = moment(this.itemData).format(this.itemValueFormat);
      this.itemValue = formatDate(this.itemData, this.itemValueFormat);
      // this.itemDisplay = moment(this.itemData).format(this.itemDisplayFormat);
      this.itemDisplay = formatDate(this.itemData, this.itemDisplayFormat);
    }
    else {
      this.itemValue = null;
    }
  }

  @Input() set displayFormat(val: string) {
    this.itemDisplayFormat = (val !== undefined && val !== null) ? val : 'yyyy-MM-dd HH:mm:ss';
    if (this.itemData && ('' !== this.itemData)) {
      // this.itemDisplay = moment(this.itemData).format(this.itemDisplayFormat);
      this.itemDisplay = formatDate(this.itemData, this.itemDisplayFormat);
    }
  }

  @Input() set valueFormat(val: string) {
    this.itemValueFormat = (val !== undefined && val !== null) ? val : 'yyyy-MM-dd HH:mm:ss';
  }

  @Input() set title(val: string) {
    this.itemTitle = (val !== undefined && val !== null) ? val : 'Select date';
  }

  @Output() valueChange = new EventEmitter()

  constructor() {
    // console.log('constr');
  }

  ngOnChanges() { }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  clearItem() {
    this.itemValue = null;
    this.itemData = null;
    this.itemDisplay = '';
    this.itemHasError = false;
    this.itemError = '';
  }

  onItemClear(event) {
    // console.log('onItemClear', this.itemValue);
    this.clearItem();
    this.valueChange.emit(this.itemValue);
  }

  onItemChange(event) {
    // console.log('onItemChange', this.itemValue, this.itemDisplay, event);
    if (event && event.detail && event.detail.value && ('' !== event.detail.value)) {
      // console.log('event.detail.value', event.detail.value);
      // const updatedItemValue = moment(event.detail.value).format(this.itemValueFormat);
      const updatedItemValue = formatDate(event.detail.value, this.itemValueFormat);
      if (updatedItemValue != this.itemValue) {
        // this.itemValue = moment(event.detail.value).format(this.itemValueFormat);
        this.itemValue = formatDate(event.detail.value, this.itemValueFormat);
        // this.itemDisplay = moment(event.detail.value).format(this.itemDisplayFormat);
        this.itemDisplay = formatDate(event.detail.value, this.itemDisplayFormat);
        this.valueChange.emit(this.itemValue);
      }
    }
    else {
      this.clearItem();
      this.valueChange.emit(this.itemValue);
    }
  }

  openCalendar() {
    this.showCalendar = true;
  }
  cancelCalendar() {
    this.showCalendar = false;
  }
}
